//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data() {
    return {
      value: "",
      switchChecked: false,
      value1: "",
      columns: ["消费/次", "消费/天", "消费/周", "消费/月"],
      showPicker: false,
      value2: "",
      columns2: ["食堂", "超市"],
      showPicker2: false,
    };
  },
  methods: {
    onConfirm(value) {
      this.value1 = value;
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.value2 = value;
      this.showPicker2 = false;
    },
  },
};
